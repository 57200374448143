import React from 'react';
import { AsideMenuItem } from './AsideMenuItem';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { userHasRole } from '../../../helpers';

export function AsideMenuMain() {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;

  return (
    <>
      {userHasRole(
        [
          'SYSTEM_ADMIN',
          'ADMIN_MEXABLOCK',
          'CALL_CENTER',
          'ADMIN_COMPANY',
          'ADMIN_PARTNER',
          'EMPLOYEE',
          'DISTRIBUTOR',
        ],
        userRoles
      ) && (
        <AsideMenuItem
          to="/dashboard"
          icon="/media/icons/duotune/graphs/gra008.svg"
          title={'Dashboard'}
          fontIcon="bi-app-indicator"
        />
      )}
      {userHasRole(
        [
          'SYSTEM_ADMIN',
          'ADMIN_MEXABLOCK',
          'CALL_CENTER',
          'ADMIN_COMPANY',
          'ADMIN_PARTNER',
          'EMPLOYEE',
          'DISTRIBUTOR',
          'REPORTES',
        ],
        userRoles
      ) && (
        <AsideMenuItemWithSub
          icon="/media/icons/duotune/maps/map010.svg"
          fontIcon="bi-layers"
          to="/operacion/"
          title="Operación"
          hasBullet={false}
        >
          {userHasRole(
            [
              'SYSTEM_ADMIN',
              'ADMIN_MEXABLOCK',
              'CALL_CENTER',
              'ADMIN_COMPANY',
              'ADMIN_PARTNER',
              'EMPLOYEE',
              'DISTRIBUTOR',
              'REPORTES',
            ],
            userRoles
          ) && (
            <AsideMenuItem
              to="/operacion/consumos"
              icon="/media/icons/duotune/finance/fin007.svg"
              title={'Consumos'}
              fontIcon="bi-app-indicator"
            />
          )}
          {/* {userHasRole(
            [
              'ADMIN_MEXABLOCK',
              'ADMIN_PARTNER',
              // 'EMPLOYEE',
              // 'DISTRIBUTOR',
            ],
            userRoles
          ) && (
            <AsideMenuItem
              to="/operacion/cuentas"
              icon="/media/icons/duotune/finance/fin008.svg"
              title={'Aprovisionamiento'}
              fontIcon="bi-app-indicator"
            />
          )} */}
          {userHasRole(
            [
              'SYSTEM_ADMIN',
              'ADMIN_MEXABLOCK',
              'CALL_CENTER',
              'ADMIN_COMPANY',
              'ADMIN_PARTNER',
              'EMPLOYEE',
              'DISTRIBUTOR',
            ],
            userRoles
          ) && (
            <AsideMenuItem
              to="/operacion/validacion"
              icon="/media/icons/duotune/arrows/arr012.svg"
              title={'Validación'}
              fontIcon="bi-app-indicator"
            />
          )}
          {userHasRole(
            [
              'SYSTEM_ADMIN',
              'ADMIN_MEXABLOCK',
              'CALL_CENTER',
              'ADMIN_COMPANY',
              'ADMIN_PARTNER',
              'EMPLOYEE',
              'DISTRIBUTOR',
            ],
            userRoles
          ) && (
            <AsideMenuItem
              to="/operacion/activaciones"
              icon="/media/icons/duotune/general/gen037.svg"
              title={'Activaciones'}
              fontIcon="bi-app-indicator"
            />
          )}
          {userHasRole(
            [
              'SYSTEM_ADMIN',
              'ADMIN_MEXABLOCK',
              'CALL_CENTER',
              'ADMIN_COMPANY',
              'ADMIN_PARTNER',
              'EMPLOYEE',
              'DISTRIBUTOR',
            ],
            userRoles
          ) && (
            <AsideMenuItem
              to="/operacion/recargas"
              icon="/media/icons/duotune/finance/fin010.svg"
              title={'Recargas'}
              fontIcon="bi-app-indicator"
            />
          )}
          {/* {userHasRole(
            [
              'SYSTEM_ADMIN',
              'ADMIN_MEXABLOCK',
              'CALL_CENTER',
              'ADMIN_COMPANY',
              'ADMIN_PARTNER',
              'EMPLOYEE',
              'DISTRIBUTOR',
            ],
            userRoles
          ) && (
            <AsideMenuItem
              to="/operacion/portabilidades"
              icon="/media/icons/duotune/arrows/arr029.svg"
              title={'Portabilidades'}
              fontIcon="bi-app-indicator"
            />
          )} */}
          {/* {userHasRole(
            [
              'SYSTEM_ADMIN',
              'ADMIN_MEXABLOCK',
              'ADMIN_COMPANY',
              'ADMIN_PARTNER',
              'EMPLOYEE',
              'DISTRIBUTOR',
              'CALL_CENTER',
            ],
            userRoles
          ) && (
            <AsideMenuItem
              to="/operacion/cambio-lada"
              icon="/media/icons/duotune/coding/cod009.svg"
              title={'Cambio de LADA'}
              fontIcon="bi-app-indicator"
            />
          )} */}
        </AsideMenuItemWithSub>
      )}

      {userHasRole(
        [
          'SYSTEM_ADMIN',
          'ADMIN_MEXABLOCK',
          'CALL_CENTER',
          'ADMIN_COMPANY',
          'ADMIN_PARTNER',
          'EMPLOYEE',
          'DISTRIBUTOR',
          'REPORTES',
        ],
        userRoles
      ) && (
        <AsideMenuItemWithSub
          icon="/media/icons/duotune/general/gen005.svg"
          fontIcon="bi-layers"
          to="/reportes/"
          title="Reportes"
          hasBullet={false}
        >
          {userHasRole(
            [
              'SYSTEM_ADMIN',
              'ADMIN_MEXABLOCK',
              'CALL_CENTER',
              'ADMIN_COMPANY',
              'ADMIN_PARTNER',
              'EMPLOYEE',
              'DISTRIBUTOR',
              'REPORTES',
            ],
            userRoles
          ) && (
            <AsideMenuItem
              to="/reportes/activaciones"
              icon="/media/icons/duotune/general/gen005.svg"
              title="Activaciones"
              fontIcon="bi-app-indicator"
            />
          )}

          {/* {userHasRole(
            [
              'SYSTEM_ADMIN',
              'ADMIN_MEXABLOCK',
              'CALL_CENTER',
              'ADMIN_COMPANY',
              'ADMIN_PARTNER',
              'EMPLOYEE',
              'DISTRIBUTOR',
            ],
            userRoles
          ) && (
            <AsideMenuItem
              to="/reportes/partners"
              icon="/media/icons/duotune/general/gen005.svg"
              title="Socios"
              fontIcon="bi-app-indicator"
            />
          )} */}

          {/* {userHasRole(
            [
              'SYSTEM_ADMIN',
              'ADMIN_MEXABLOCK',
              'CALL_CENTER',
              'ADMIN_COMPANY',
              'ADMIN_PARTNER',
              'EMPLOYEE',
              'DISTRIBUTOR',
            ],
            userRoles
          ) && (
            <AsideMenuItem
              to="/reportes/portabilidad"
              icon="/media/icons/duotune/general/gen005.svg"
              title="Portabilidad"
              fontIcon="bi-app-indicator"
            />
          )} */}

          {userHasRole(
            [
              'SYSTEM_ADMIN',
              'ADMIN_MEXABLOCK',
              'CALL_CENTER',
              'ADMIN_COMPANY',
              'ADMIN_PARTNER',
              'EMPLOYEE',
              'DISTRIBUTOR',
              'REPORTES',
            ],
            userRoles
          ) && (
            <AsideMenuItem
              to="/reportes/recargas"
              icon="/media/icons/duotune/general/gen005.svg"
              title="Recargas"
              fontIcon="bi-app-indicator"
            />
          )}
          {userHasRole(
            [
              'SYSTEM_ADMIN',
              'ADMIN_MEXABLOCK',
              'CALL_CENTER',
              'ADMIN_COMPANY',
              'ADMIN_PARTNER',
              'EMPLOYEE',
              'DISTRIBUTOR',
              'REPORTES',
            ],
            userRoles
          ) && (
            <AsideMenuItem
              to="/reportes/estado_de_cuenta"
              icon="/media/icons/duotune/general/gen005.svg"
              title="Estado de cuenta"
              fontIcon="bi-app-indicator"
            />
          )}
          {/* {userHasRole(
            [
              'SYSTEM_ADMIN',
              'ADMIN_MEXABLOCK',
              'CALL_CENTER',
              'ADMIN_COMPANY',
              'ADMIN_PARTNER',
              'EMPLOYEE',
              'DISTRIBUTOR',
            ],
            userRoles
          ) && (
            <AsideMenuItem
              to="/reportes/comisiones"
              icon="/media/icons/duotune/general/gen005.svg"
              title="Comisiones"
              fontIcon="bi-app-indicator"
            />
          )} */}
        </AsideMenuItemWithSub>
      )}
      {/* {userHasRole(
        [
          'SYSTEM_ADMIN',
          'ADMIN_MEXABLOCK',
          'CALL_CENTER',
          'ADMIN_COMPANY',
          'ADMIN_PARTNER',
          'EMPLOYEE',
          'DISTRIBUTOR',
        ],
        userRoles
      ) && (
        <div className="menu-item">
          <div className="menu-content pt-5 pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              Administración
            </span>
          </div>
        </div>
      )} */}
      {/* {userHasRole(['SYSTEM_ADMIN'], userRoles) && (
        <AsideMenuItem
          to="/usuarios"
          title="Usuarios del sistema"
          icon="/media/icons/duotune/general/gen049.svg"
        />
      )} */}

      {/* {userHasRole(['ADMIN_MEXABLOCK', 'CALL_CENTER'], userRoles) && (
        <>
          <AsideMenuItem
            to="/facturacion"
            icon="/media/icons/duotune/finance/fin008.svg"
            title={'Facturación'}
            fontIcon="bi-app-indicator"
          />
          <AsideMenuItem
            to="/empresas"
            title="Socios / Empresas"
            icon="/media/icons/duotune/communication/com001.svg"
          />
          <AsideMenuItem
            to="/call-center"
            title="Call Center"
            icon="/media/icons/duotune/technology/teh002.svg"
          />

          <AsideMenuItemWithSub
            icon="/media/icons/duotune/technology/teh002.svg"
            fontIcon="bi-layers"
            to="/call-center/"
            title="Call Center"
            hasBullet={false}
          >
            {userHasRole(['ADMIN_MEXABLOCK', 'CALL_CENTER'], userRoles) && (
              <>
                <AsideMenuItem
                  to="/call-center/usuarios"
                  icon="/media/icons/duotune/communication/com006.svg"
                  title={'Usuarios'}
                  fontIcon="bi-app-indicator"
                />
                <AsideMenuItem
                  to="/call-center/suspension"
                  icon="/media/icons/duotune/coding/cod009.svg"
                  title={'Suspensión'}
                  fontIcon="bi-app-indicator"
                />
                <AsideMenuItem
                  to="/call-center/cancelacion"
                  icon="/media/icons/duotune/coding/cod009.svg"
                  title={'Cancelación'}
                  fontIcon="bi-app-indicator"
                />
                <AsideMenuItem
                  to="/call-center/reactivacion"
                  icon="/media/icons/duotune/coding/cod009.svg"
                  title={'Reactivación'}
                  fontIcon="bi-app-indicator"
                />

                <AsideMenuItem
                  to="/call-center/cambio-sim"
                  icon="/media/icons/duotune/coding/cod009.svg"
                  title={'Cambio de SIM'}
                  fontIcon="bi-app-indicator"
                />
              </>
            )}
          </AsideMenuItemWithSub>

          <AsideMenuItem
            to="/admin_distribuidores"
            icon="/media/icons/duotune/communication/com001.svg"
            title={'Distribuidores Inactivos'}
            fontIcon="bi-app-indicator"
          />
        </>
      )} */}

      {/* {userHasRole(['ADMIN_PARTNER'], userRoles) && (
        <AsideMenuItem
          to="/distribuidores"
          icon="/media/icons/duotune/communication/com001.svg"
          title={'Distribuidores'}
          fontIcon="bi-app-indicator"
        />
      )} */}

      {/* {userHasRole(
        [
          'SYSTEM_ADMIN',
          'ADMIN_MEXABLOCK',
          'CALL_CENTER',
          'ADMIN_COMPANY',
          'ADMIN_PARTNER',
          'EMPLOYEE',
          'DISTRIBUTOR',
        ],
        userRoles
      ) && (
        <AsideMenuItemWithSub
          icon="/media/icons/duotune/communication/com006.svg"
          fontIcon="bi-layers"
          to="/usuarios/"
          title="Usuarios"
          hasBullet={false}
        >
          <AsideMenuItem
            to="/clientes"
            icon="/media/icons/duotune/communication/com006.svg"
            title={'Clientes'}
            fontIcon="bi-app-indicator"
          />
        </AsideMenuItemWithSub>
      )} */}

      {/* {userHasRole(['ADMIN_MEXABLOCK'], userRoles) && (
        <>
          <AsideMenuItem
            to="/notificaciones"
            title="Campañas"
            icon="/media/icons/duotune/communication/com004.svg"
          />
        </>
      )} */}

      {/* <AsideMenuItemWithSub
        icon="/media/icons/duotune/general/gen046.svg"
        fontIcon="bi-layers"
        to="/ayuda/"
        title="Ayuda"
        hasBullet={false}
      >
        <AsideMenuItem
          to="/ayuda/configuracion_equipos"
          icon="/media/icons/duotune/electronics/elc002.svg"
          title="Configuración de equipos"
          fontIcon="bi-app-indicator"
        />

        <AsideMenuItem
          to="/ayuda/contacto"
          icon="/media/icons/duotune/electronics/elc002.svg"
          title="Contacto"
          fontIcon="bi-app-indicator"
        />
      </AsideMenuItemWithSub> */}
    </>
  );
}
